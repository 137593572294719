import styled from 'styled-components'
import { Icon } from 'antd'

export const ModalIcon = styled(Icon)`
  font-size: 50px;
  margin-bottom: 16px;
`
export const ModalMessage = styled.p`
  font-weight: 500;
`
export const ModalCTA = styled.p`
  font-size: 12px;
  text-decoration: underline;
  cursor: pointer;
`
