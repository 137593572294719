import store from 'Redux/store'
import { analytics } from 'Common/firebase'
import { PAGE_VIEW_EVENT, GENERATE_LIST_EVENT } from '../action-types'

export const pageViewEvent = () => dispatch => {
  const { storeId, email } = store.getState().user
  analytics.logEvent('page_view', {
    env: process.env.REACT_APP_ENV,
    storeId: `store_${storeId}`,
    email,
  })
  return dispatch({ type: PAGE_VIEW_EVENT })
}
export const generateListEvent = () => dispatch => {
  const { storeId, email } = store.getState().user
  const { currentSelection } = store.getState().data
  analytics.logEvent('generate_list', {
    env: process.env.REACT_APP_ENV,
    storeId: `store_${storeId}`,
    email,
    ...currentSelection,
  })
  return dispatch({ type: GENERATE_LIST_EVENT })
}
