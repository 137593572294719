import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'antd'
import ListsCard from 'Components/ListsCard'
import DepartmentsCard from 'Components/DepartmentsCard'
const CardsRow = props => {
  const {
    listId,
    departments,
    updateSelectedLists,
    updateSelectedDepartments,
  } = props
  return (
    <Row gutter={8}>
      <Col xs={24} sm={24} md={{ span: 10, offset: 2 }}>
        <ListsCard listId={listId} onChangeCallback={updateSelectedLists} />
      </Col>
      <Col xs={24} sm={24} md={10}>
        <DepartmentsCard
          departments={departments}
          onChangeCallback={updateSelectedDepartments}
        />
      </Col>
    </Row>
  )
}
CardsRow.propTypes = {
  departments: PropTypes.array,
  listId: PropTypes.array,
  updateSelectedLists: PropTypes.func,
  updateSelectedDepartments: PropTypes.func,
}
export default CardsRow
