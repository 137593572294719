import styled from 'styled-components'
import { SmallText } from 'Common/styled'
import { colors } from 'Common/constants'
export const LastImportText = styled(SmallText)`
  margin: 0;
  color: ${colors.grey3};
  @media (max-width: 767px) {
    font-size: 10px;
  }
`
