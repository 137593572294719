import API from './axiosConfig'
export default {
  user: {
    authenticate: token =>
      API.get('/user/details', {
        headers: { Authorization: `Bearer ${token}` }
      })
  },
  data: {
    getParameters: params => API.get('/parameters/read', { params }),
    getPreview: params => API.post('/list/preview', params),
    getPdf: (params, cancelToken) =>
      API.post('/list/generate', params, { responseType: 'blob', cancelToken })
  }
}
