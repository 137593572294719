import React from 'react'
import PropTypes from 'prop-types'
import { LastImportText } from './styled'
const LastImport = props => {
  const { data } = props
  return (
    <LastImportText>
      Ultima lista aggiornata {data.relativeDay} alle {data.time}
    </LastImportText>
  )
}
LastImport.propTypes = {
  data: PropTypes.shape({
    day: PropTypes.string,
    relativeDay: PropTypes.string,
    time: PropTypes.string
  })
}
export default LastImport
