import React from 'react'
import PropTypes from 'prop-types'
import { StyledFooter } from './styled'

const Footer = props => {
  const { storeId } = props
  return (
    <StyledFooter>
      <small>v{process.env.REACT_APP_VERSION}</small>
      {storeId && <small>Negozio {storeId}</small>}
    </StyledFooter>
  )
}
Footer.propTypes = {
  storeId: PropTypes.number
}
export default Footer
