import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'ramda'

import User from 'Components/User'
import LastImport from 'Components/LastImport'
import { StyledHeader, HeaderInnerWrapper, HeaderBar, AppLogo } from './styled'

const Header = props => {
  const { user, lastImport } = props
  return (
    <Fragment>
      <HeaderBar />
      <StyledHeader>
        <HeaderInnerWrapper lastImport={lastImport}>
          <AppLogo src="/assets/img/LM-logo.png" />
          {lastImport && <LastImport data={lastImport} />}
          {!isEmpty(user) && <User user={user} />}
        </HeaderInnerWrapper>
      </StyledHeader>
    </Fragment>
  )
}

Header.propTypes = {
  lastImport: PropTypes.shape({
    day: PropTypes.string,
    relativeDay: PropTypes.string,
    time: PropTypes.string,
  }),
  user: PropTypes.object,
}
export default Header
