export const SET_LOADING = 'SET_LOADING'
export const SET_LOADING_MODAL = 'SET_LOADING_MODAL'
export const SET_SUCCESS_MODAL = 'SET_SUCCESS_MODAL'
export const RESET_MODAL = 'RESET_MODAL'

// USER
export const VALIDATE_TOKEN = 'VALIDATE_TOKEN'

// DATA
export const GET_PARAMETERS = 'GET_PARAMETERS'
export const GET_PREVIEW = 'GET_PREVIEW'
export const GET_PDF = 'GET_PDF'
export const UPDATE_SELECTED_LISTS = 'UPDATE_SELECTED_LISTS'
export const UPDATE_SELECTED_DEPARTMENTS = 'UPDATE_SELECTED_DEPARTMENTS'

// ANALYTICS
export const PAGE_VIEW_EVENT = 'PAGE_VIEW_EVENT'
export const GENERATE_LIST_EVENT = 'GENERATE_LIST_EVENT'
