import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'antd'
import { colors } from 'Common/constants'
import { ModalIcon, ModalCTA, ModalMessage } from './styled'
const FeedbackModal = props => {
  const style = {
    backgroundColor: colors.main,
    color: colors.white,
    textAlign: 'center',
    padding: '24px 10px'
  }
  const renderIcon = modalType => {
    switch (modalType) {
      case 'loading':
        return 'loading-3-quarters'
      case 'success':
        return 'check-circle'
      default:
        return 'ellipsis'
    }
  }
  const { type, message, closeText, onCancel } = props
  return (
    <Modal
      {...props}
      width={260}
      centered
      maskClosable={false}
      bodyStyle={style}
      footer={null}>
      <ModalIcon type={renderIcon(type)} spin={type === 'loading'} />
      <ModalMessage>{message}</ModalMessage>
      <ModalCTA onClick={onCancel}>{closeText}</ModalCTA>
    </Modal>
  )
}
FeedbackModal.propTypes = {
  closeText: PropTypes.string,
  message: PropTypes.string,
  onCancel: PropTypes.func,
  type: PropTypes.string
}
export default FeedbackModal
