import styled from 'styled-components'
import { Layout } from 'antd'
import { colors } from 'Common/constants'

export const HeaderBar = styled.div`
  height: 16px;
  background-color: ${colors.main};
`
export const StyledHeader = styled(Layout.Header)`
  background-color: ${colors.white};
  border: 1px solid ${colors.grey2};
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.08);
  height: 72px;
  z-index: 999;
  @media (max-width: 767px) {
    padding: 0 8px;
  }
`
export const HeaderInnerWrapper = styled.div`
  display: grid;
  grid-gap: 50px;
  grid-template-columns: 1fr repeat(2, auto);
  align-items: center;
  max-width: 1200px;
  margin: auto;
  @media (max-width: 767px) {
    grid-gap: 16px;
    grid-template-columns: auto 1fr auto auto;
  }
`
export const AppLogo = styled.img`
  @media (max-width: 767px) {
    width: 50px;
  }
`
