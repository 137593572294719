import store from 'Redux/store'
import * as actionTypes from '../action-types'
import axios from 'axios'
import api from 'Common/api'
import {
  handleServerError,
  handleFileDownload,
  cancelTokenSource,
} from 'Common/utils'
import { SUCCESS_STATUS, ERROR_STATUS } from 'Common/constants'
import { clone } from 'ramda'

export const getParameters = params => async dispatch => {
  return await api.data
    .getParameters(params)
    .then(res => {
      dispatch({
        type: actionTypes.GET_PARAMETERS,
        payload: res.data,
      })
    })
    .catch(err => handleServerError(err))
}
export const getPreview = params => async dispatch => {
  return await api.data
    .getPreview(params)
    .then(res => {
      const { referencesNumber, ordersNumber } = res.data
      dispatch({
        type: actionTypes.GET_PREVIEW,
        payload: {
          referencesCount: referencesNumber,
          ordersCount: ordersNumber,
        },
      })
    })
    .catch(err => handleServerError(err))
}
export const getPdf = params => async dispatch => {
  return await api.data
    .getPdf(params, cancelTokenSource.token)
    .then(res => {
      handleFileDownload(res.data, 'doc.pdf', res.headers['content-type'])
      dispatch({ type: actionTypes.GET_PDF })
      return SUCCESS_STATUS
    })
    .catch(err => {
      !axios.isCancel(err) && handleServerError(err)
      return ERROR_STATUS
    })
}

const updateData = (key, itemData) => {
  const currentData = store.getState().data
  const newSelection = clone(currentData.currentSelection)
  const allIndex = newSelection[key].findIndex(item => item === 'all')
  const itemIndex = newSelection[key].findIndex(item => item === itemData.value)
  const newKeyData = clone(currentData[key])
  if (itemData.value === 'all') {
    newSelection[key] = [itemData.value]
    newKeyData.forEach((item, index) =>
      index > 0 ? (item.checked = false) : (item.checked = true)
    )
  } else {
    if (itemData.checked) {
      allIndex > -1 && newSelection[key].splice(allIndex, 1)
      newSelection[key].push(itemData.value)
      newKeyData[0].checked = false
    } else {
      newSelection[key].splice(itemIndex, 1)
      if (newSelection[key].length === 0) {
        newSelection[key].push('all')
        newKeyData[0].checked = true
      }
    }
    newKeyData[itemData.index].checked = itemData.checked
  }
  return {
    currentSelection: newSelection,
    [key]: newKeyData,
  }
}
export const updateSelectedLists = itemData => {
  return {
    type: actionTypes.UPDATE_SELECTED_LISTS,
    payload: updateData('listId', itemData),
  }
}
export const updateSelectedDepartments = itemData => {
  return {
    type: actionTypes.UPDATE_SELECTED_DEPARTMENTS,
    payload: updateData('departments', itemData),
  }
}
