import React from 'react'
import PropTypes from 'prop-types'
import { StyledCard } from './styled'
import { colors } from 'Common/constants'
const Card = props => {
  const { children, title } = props
  return (
    <StyledCard
      title={title}
      size="small"
      headStyle={{
        color: colors.grey4,
        fontSize: '14px',
        border: 'none',
        padding: 0
      }}>
      {children}
    </StyledCard>
  )
}
Card.propTypes = {
  title: PropTypes.string
}
export default Card
