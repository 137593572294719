import * as actionTypes from '../action-types'

export const initialState = {
  isLoading: true,
  modalProps: {}
}

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.SET_LOADING:
      return {
        ...state,
        isLoading: payload
      }
    case actionTypes.SET_LOADING_MODAL:
      return {
        ...state,
        modalProps: {
          visible: true,
          closable: false,
          type: 'loading',
          closeText: "Annulla l'operazione",
          ...payload
        }
      }
    case actionTypes.SET_SUCCESS_MODAL: {
      return {
        ...state,
        modalProps: {
          visible: true,
          closable: true,
          type: 'success',
          closeText: 'Crea una nuova lista',
          ...payload
        }
      }
    }
    case actionTypes.RESET_MODAL:
      return {
        ...state,
        modalProps: initialState.modalProps
      }
    default:
      return state
  }
}

export default reducer
