import { combineReducers } from 'redux'
import app from './app-reducer'
import user from './user-reducer'
import data from './data-reducer'

const combinedReducers = combineReducers({
  app,
  user,
  data,
})

export default combinedReducers
