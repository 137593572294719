import { notification } from 'antd'
import axios from 'axios'
export let cancelTokenSource = axios.CancelToken.source()

export const getQueryParam = param => {
  const urlParams = new URLSearchParams(window.location.search)
  return urlParams.get(param)
}
export const feedbackNotification = (type, title, subtitle) => {
  return notification[type]({
    message: title,
    description: subtitle
  })
}
export const handleServerError = error => {
  if (error.response) {
    switch (error.response.status) {
      case 400:
        return feedbackNotification('error', 'Invio non riuscito', "Controlla i parametri e riprova")
      case 404:
        return feedbackNotification('error', 'Nessun risultato')
      default:
        return feedbackNotification('error', 'Si è verificato un errore', "Riprova")
      }
    }
    return feedbackNotification('error', 'Si è verificato un errore', "Riprova")
}
export const handleFileDownload = (data, filename, mime, bom) => {
  const blobData = typeof bom !== 'undefined' ? [bom, data] : [data]
  const blob = new Blob(blobData, { type: mime || 'application/octet-stream' })
  if (typeof window.navigator.msSaveBlob !== 'undefined') {
    // IE workaround for "HTML7007: One or more blob URLs were
    // revoked by closing the blob for which they were created.
    // These URLs will no longer resolve as the data backing
    // the URL has been freed."
    window.navigator.msSaveBlob(blob, filename)
  } else {
    const blobURL = window.URL.createObjectURL(blob)
    const tempLink = document.createElement('a')
    tempLink.style.display = 'none'
    tempLink.href = blobURL
    tempLink.setAttribute('download', filename)

    // Safari thinks _blank anchor are pop ups. We only want to set _blank
    // target if the browser does not support the HTML5 download attribute.
    // This allows you to download files in desktop safari if pop up blocking
    // is enabled.
    if (typeof tempLink.download === 'undefined') {
      tempLink.setAttribute('target', '_blank')
    }

    document.body.appendChild(tempLink)
    tempLink.click()
    document.body.removeChild(tempLink)
    window.URL.revokeObjectURL(blobURL)
  }
}
export const cancelAxiosRequest = () => {
  cancelTokenSource && cancelTokenSource.cancel('Request canceled by user')
  cancelTokenSource = axios.CancelToken.source()
}