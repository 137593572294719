import styled from 'styled-components'
import { Button, Tag, Icon, Layout } from 'antd'
import { colors } from './constants'
export const PaddedContent = styled(Layout.Content)`
  padding: 16px 0;
  min-height: calc(100vh - 116px);
  max-width: 1200px;
  margin: auto;
  text-align: center;
  @media (max-width: 767px) {
    padding: 16px 8px;
  }
`
export const TextTitle = styled.p`
  color: ${props => props.color || colors.grey4};
  font-weight: 500;
  margin-bottom: 20px;
  line-height: normal;
`
export const SmallText = styled(TextTitle)`
  font-size: 12px;
  font-weight: normal;
`
export const BigTitle = styled(TextTitle)`
  color: ${props => props.color || colors.grey4};
  font-size: 27px;
  text-transform: uppercase;
`
export const AccentSpan = styled.span`
  color: ${colors.main};
`
export const StyledButton = styled(Button)`
  text-transform: uppercase;
`
export const CheckableTag = styled(Tag.CheckableTag)`
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  line-height: 32px !important;
  &:not(.ant-tag-checkable-checked) {
    background-color: ${colors.grey1} !important;
    border-color: ${colors.grey2} !important;
    color: ${colors.grey3} !important;
  }
  &.btn-all {
    line-height: 76px !important;
  }
`
export const StyledIcon = styled(Icon)`
  font-size: 24px;
  color: ${colors.grey3};
`
