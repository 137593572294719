import * as actionTypes from '../action-types'

const reducer = (state = {}, { type, payload }) => {
  switch (type) {
    case actionTypes.VALIDATE_TOKEN:
      return {
        ...state,
        ...payload
      }
    default:
      return state
  }
}
export default reducer
