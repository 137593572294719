import * as actionTypes from '../action-types'
export const initialState = {
  lastImport: {},
  listId: [
    { value: 'all', label: 'Tutti', checked: true },
    { value: 'RT', label: '(1) Rotture TOP 1', checked: false },
    { value: 'QR', label: '(2) Quasi Rotture', checked: false },
    { value: 'SO', label: '(3) Sospetti', checked: false },
    { value: 'SC', label: '(4) Scarti Invent.', checked: false },
    { value: 'FU', label: '(5) Furti', checked: false },
    { value: 'SRM', label: '(6) SRM', checked: false },
    { value: 'CQ', label: '(7) QC', checked: false },
  ],
  departments: [
    { value: 'all', label: 'Tutti', checked: true },
    { value: '1', label: '1', checked: false },
    { value: '2', label: '2', checked: false },
    { value: '3', label: '3', checked: false },
    { value: '4', label: '4', checked: false },
    { value: '5', label: '5', checked: false },
    { value: '6', label: '6', checked: false },
    { value: '7', label: '7', checked: false },
    { value: '8', label: '8', checked: false },
    { value: '9', label: '9', checked: false },
    { value: '10', label: '10', checked: false },
    { value: '11', label: '11', checked: false },
    { value: '12', label: '12', checked: false },
    { value: '13', label: '13', checked: false },
    { value: '14', label: '14', checked: false },
  ],
  currentSelection: {
    listId: ['all'],
    departments: ['all'],
  },
}
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.GET_PARAMETERS:
      return {
        ...state,
        lastImport: payload.lastImport,
      }
    case actionTypes.GET_PREVIEW:
      return {
        ...state,
        referencesCount: payload.referencesCount,
      }
    case actionTypes.UPDATE_SELECTED_LISTS:
      return {
        ...state,
        currentSelection: payload.currentSelection,
        listId: payload.listId,
      }
    case actionTypes.UPDATE_SELECTED_DEPARTMENTS:
      return {
        ...state,
        currentSelection: payload.currentSelection,
        departments: payload.departments,
      }
    default:
      return state
  }
}
