import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { equals } from 'ramda'

import {
  setLoadingModal,
  setSuccessModal,
  resetModal,
} from 'Redux/actions/app-actions'
import {
  getParameters,
  getPreview,
  getPdf,
  updateSelectedLists,
  updateSelectedDepartments,
} from 'Redux/actions/data-actions'
import {
  pageViewEvent,
  generateListEvent,
} from 'Redux/actions/analytics-actions'

import CardsRow from 'Components/CardsRow'
import { TextTitle, BigTitle, AccentSpan, StyledButton } from 'Common/styled'

import { SUCCESS_STATUS, feedbackModalTexts, colors } from 'Common/constants'
import { cancelAxiosRequest } from 'Common/utils'

export class Home extends Component {
  static propTypes = {
    currentSelection: PropTypes.object,
    departments: PropTypes.array,
    generateListEvent: PropTypes.func,
    getParameters: PropTypes.func,
    getPdf: PropTypes.func,
    getPreview: PropTypes.func,
    listId: PropTypes.array,
    pageViewEvent: PropTypes.func,
    referencesCount: PropTypes.number,
    resetModal: PropTypes.func,
    setLoadingModal: PropTypes.func,
    setSuccessModal: PropTypes.func,
    user: PropTypes.object,
    updateSelectedDepartments: PropTypes.func,
    updateSelectedLists: PropTypes.func,
  }
  componentDidMount() {
    const {
      currentSelection,
      getParameters,
      getPreview,
      pageViewEvent,
    } = this.props
    const { storeId } = this.props.user
    getParameters({ storeId })
    getPreview({ storeId, ...currentSelection })
    // Analytics page_view event
    pageViewEvent()
  }
  componentDidUpdate(prevProps) {
    const { currentSelection, getPreview } = this.props
    const { storeId } = this.props.user
    if (!equals(prevProps.currentSelection, currentSelection)) {
      getPreview({ storeId, ...currentSelection })
    }
  }
  downloadPdf = () => {
    const {
      currentSelection,
      setLoadingModal,
      setSuccessModal,
      resetModal,
      getPdf,
      generateListEvent,
    } = this.props
    const { storeId, email } = this.props.user
    setLoadingModal({
      onCancel: cancelAxiosRequest,
      message: feedbackModalTexts.loading.list,
    })
    getPdf({ storeId, email, ...currentSelection }).then(res => {
      if (res === SUCCESS_STATUS) {
        setSuccessModal({
          onCancel: resetModal,
          message: feedbackModalTexts.success.list,
        })
        // Analytics generate_list event
        generateListEvent()
      } else {
        resetModal()
      }
    })
  }
  render() {
    const {
      listId,
      departments,
      referencesCount,
      updateSelectedLists,
      updateSelectedDepartments,
    } = this.props
    return (
      <>
        <BigTitle color={colors.main}>Crea la tua lista inventario</BigTitle>
        <CardsRow
          listId={listId}
          departments={departments}
          updateSelectedLists={updateSelectedLists}
          updateSelectedDepartments={updateSelectedDepartments}
        />
        <TextTitle>
          Stai generando una lista di <AccentSpan>{referencesCount}</AccentSpan>{' '}
          referenze
        </TextTitle>
        <StyledButton type="primary" onClick={this.downloadPdf}>
          Scarica la lista
        </StyledButton>
      </>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user,
  listId: state.data.listId,
  departments: state.data.departments,
  referencesCount: state.data.referencesCount,
  currentSelection: state.data.currentSelection,
})
const mapDispatchToProps = {
  setLoadingModal,
  setSuccessModal,
  resetModal,
  getParameters,
  getPreview,
  getPdf,
  updateSelectedLists,
  updateSelectedDepartments,
  pageViewEvent,
  generateListEvent,
}
export default connect(mapStateToProps, mapDispatchToProps)(Home)
