import firebase from 'firebase/app'
import 'firebase/analytics'

const firebaseConfig = {
  apiKey: 'AIzaSyADoU-rr7gNtrxcjXyzbm5nX107Mmhgxdc',
  authDomain: 'lmit-permamentinvent-itlm.firebaseapp.com',
  databaseURL: 'https://lmit-permamentinvent-itlm.firebaseio.com',
  projectId: 'lmit-permamentinvent-itlm',
  storageBucket: 'lmit-permamentinvent-itlm.appspot.com',
  messagingSenderId: '432211255301',
  appId: '1:432211255301:web:a249d265a431a0d520bc6c',
  measurementId: 'G-4EHF3DRW7S',
}

firebase.initializeApp(firebaseConfig)
export default firebase
export const analytics = firebase.analytics()
