import * as actionTypes from '../action-types'

export const setLoading = loading => {
  return { type: actionTypes.SET_LOADING, payload: loading }
}
export const setLoadingModal = additionalProps => {
  return { type: actionTypes.SET_LOADING_MODAL, payload: additionalProps }
}
export const setSuccessModal = additionalProps => {
  return { type: actionTypes.SET_SUCCESS_MODAL, payload: additionalProps }
}
export const resetModal = () => {
  return { type: actionTypes.RESET_MODAL }
}