const env = process.env.REACT_APP_ENV
const redirectAfterLogin = encodeURIComponent(window.location.href)
const envConfig = {
  dev: {
    LOGIN_URL: `https://test-lmit-permamentinvent-itlm-ping-cc5goesrua-ew.a.run.app/sso/auth/?sso&redirectAfterLogin=${redirectAfterLogin}`,
    API_URL:
      'https://test-permamentinvent-backend-cc5goesrua-ew.a.run.app/api/v1',
  },
  test: {
    LOGIN_URL: `https://test-lmit-permamentinvent-itlm-ping-cc5goesrua-ew.a.run.app/sso/auth/?sso&redirectAfterLogin=${redirectAfterLogin}`,
    API_URL:
      'https://test-permamentinvent-backend-cc5goesrua-ew.a.run.app/api/v1',
  },
  prod: {
    LOGIN_URL: `https://prod-lmit-permamentinvent-itlm-ping-cc5goesrua-ew.a.run.app/sso/auth/?sso&redirectAfterLogin=${redirectAfterLogin}`,
    API_URL: 'https://inventory-backend.cloud.leroymerlin.it/api/v1',
  },
}

export default envConfig[env]
