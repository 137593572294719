import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'antd'
import Card from 'Components/Card'
import { CheckableTag } from 'Common/styled'
const DepartmentsCard = props => {
  const { departments, onChangeCallback } = props
  return (
    <Card title="Scegli i reparti">
      <Row gutter={[8, 8]}>
        {departments.map((item, index) => (
          <Col span={3} key={index}>
            <CheckableTag
              className={index === 0 && 'btn-all'}
              value={item.value}
              checked={item.checked}
              onChange={checked =>
                onChangeCallback({ checked, value: item.value, index })
              }>
              {item.label}
            </CheckableTag>
          </Col>
        ))}
      </Row>
    </Card>
  )
}
DepartmentsCard.propTypes = {
  departments: PropTypes.array,
  onChangeCallback: PropTypes.func,
}
export default DepartmentsCard
